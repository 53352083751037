import { Icon } from '@gassan-ui'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import { cn } from '@lib/cn'

type Props = {
  onChange: (value: string) => void
  placeholder: string
}

let timer

const SearchInput: FC<Props> = ({ onChange, placeholder }) => {
  const [value, setValue] = useState('')
  const [query, setQuery] = useState('')
  const {
    t,
    i18n: { language },
  } = useTranslation('other')
  const router = useRouter()
  useEffect(() => {
    clearTimeout(timer)
    timer = setTimeout(() => setQuery(value), 100)
    return () => {
      clearTimeout(timer)
    }
  }, [value])

  useEffect(() => onChange(query), [query]) // eslint-disable-line

  const handleSubmit = (e) => {
    e.preventDefault()
    router.push({
      pathname: `/${language}/${t('search').toLowerCase()}`,
      query: {
        s: query,
      },
    })
  }
  return (
    <div
      className={cn(
        'relative mt-2 flex items-center',
        'border-shades-800 border-b',
        'lg:border-b-2',
        'transition-[border] duration-200 ease-in-out',
      )}
    >
      <Icon
        icon="search"
        size={['40px', '40px', '56px', '64px']}
        ml={['-8px', '-8px', '-12px', '-16px']}
      />
      <form onSubmit={handleSubmit} className="w-full">
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
          autoFocus
          className={cn(
            'left-0 top-0',
            'h-14 w-[calc(100%-48px)] leading-[56px]',
            'sm:h-16 sm:w-[calc(100%-64px)] sm:text-2xl sm:leading-[64px]',
            'lg:w-[calc(100%-80px)] lg:text-[34px]',
            'xl:h-20 xl:text-[40px] xl:leading-[80px]',
            'font-heading text-xl leading-[1.3] tracking-[0.03em]',
            'text-shades-800 border-none bg-transparent outline-none',
            'placeholder:text-shades-400',
          )}
        />
      </form>
    </div>
  )
}

export default SearchInput
