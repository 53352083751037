import { ListImageLink } from '../utils/types'
import { Text } from '@components/gassan-ui/Typography/Text'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import React, { FC } from 'react'
import { useInView } from 'react-intersection-observer'
import { cn } from '@lib/cn'

type HeaderImageListColumnProps = {
  title: string
  readMoreLink?: React.ReactNode
  links: ListImageLink[]
}

export const HeaderImageListColumn: FC<HeaderImageListColumnProps> = ({
  title,
  readMoreLink,
  links,
}) => {
  const { t } = useTranslation('navigation')
  const [ref, lastItemInView] = useInView()

  return (
    <div
      className={cn(
        'relative grid h-full w-full grid-flow-row grid-rows-[auto_1fr_auto] overflow-hidden py-2',
      )}
    >
      <Text variant="small" className="mb-4 text-shade-800">
        {title}
      </Text>
      <ul className={cn('mb-4 h-full overflow-auto pb-2')}>
        {links.map((item, key) => {
          const itemUrl = item.url.startsWith('/') ? item.url : `/${item.url}`
          let href = itemUrl

          if (href === '/tudor') {
            href = t('about-tudor')
          }

          return (
            <li key={key}>
              <Link href={href} passHref>
                <a
                  className={cn(
                    'group mb-3 grid grid-cols-[40px_1fr] items-center gap-3',
                    'lg:grid-cols-[60px_1fr]',
                  )}
                >
                  <div className={cn('flex h-10 w-10 items-center justify-center', 'lg:hidden')}>
                    <Image alt={item.label} src={item.src} layout="fixed" height={20} width={20} />
                  </div>
                  <div
                    className={cn('hidden h-[60px] w-[60px] items-center justify-center lg:flex')}
                  >
                    <Image alt={item.label} src={item.src} layout="fixed" height={40} width={40} />
                  </div>
                  <span
                    data-text
                    className={cn(
                      'font-body relative mb-0 inline-block justify-self-start bg-[length:100%_1px] bg-[0_100%] bg-no-repeat text-sm text-shade-800',
                      'sm:text-xl',
                      'xl:text-2xl',
                      'group-hover:bg-gradient-to-b group-hover:from-black group-hover:to-black',
                    )}
                  >
                    {item.label}
                  </span>
                </a>
              </Link>
            </li>
          )
        })}
        <li ref={ref} />
      </ul>
      {!lastItemInView && (
        <div
          className={cn(
            'pointer-events-none absolute bottom-8 left-0 h-20 w-full',
            'bg-gradient-to-b from-transparent to-pampas',
          )}
        />
      )}
      {readMoreLink && <div className="justify-self-start">{readMoreLink}</div>}
    </div>
  )
}
