import { RolexOrTudorPlaque } from '../RolexOrTudorPlaque'
import { cn } from '@lib/cn'
import { FC } from 'react'

const HeaderMobileNavFooter: FC = () => {
  return (
    <footer
      className={cn(
        'fixed bottom-0 flex w-full items-center justify-center',
        'h-[88px] md:h-[120px]',
        'z-[4] bg-pampas',
        'border-t border-tabaccoBrown/10',
        '[&_img]:max-h-[70px]',
      )}
    >
      <RolexOrTudorPlaque />
    </footer>
  )
}

export default HeaderMobileNavFooter
