import { Box } from '@gassan-ui'
import { FC } from 'react'
import { cn } from '@lib/cn'

const Image: FC<{ variant: string; src?: string; alt?: string }> = ({ variant, alt, ...props }) => {
  const baseStyles = cn(
    'transition-opacity duration-200 ease-in',
    'w-[56px] h-[56px] sm:w-[72px] sm:h-[72px]',
    'object-center rounded',
    'bg-white',
    variant === 'product' || variant === 'brand' ? 'object-contain' : '',
    variant === 'category' ? 'object-cover' : '',
    variant === 'brand' ? 'p-4' : '',
  )

  return <img alt={alt} className={baseStyles} loading="lazy" {...props} />
}

const Anchor: FC<{ href: string; children: React.ReactNode }> = ({ children, ...props }) => (
  <a
    className={cn('group mb-4 flex flex-wrap', '@hover:hover:opacity-80 focus:opacity-80')}
    {...props}
  >
    {children}
  </a>
)

const Content: FC<{ children: React.ReactNode }> = ({ children }) => (
  <Box
    className={cn(
      'overflow-hidden',
      'w-[calc(100%-72px)] sm:w-[calc(100%-88px)]',
      'flex flex-col justify-center py-2 pl-3',
    )}
  >
    {children}
  </Box>
)

const StyledSpan: FC<{
  children: React.ReactNode
  className?: string
}> = ({ children, className }) => (
  <div
    className={cn(
      'block w-full overflow-x-hidden text-ellipsis whitespace-nowrap',
      'font-body text-shades-800 leading-none',
      className,
    )}
  >
    {children}
  </div>
)

type Props = {
  image: string
  title: string
  id?: string
  subtitle?: string
  price?: string
  href: string
  variant: 'product' | 'brand' | 'category'
}

const Result: FC<Props> = ({ image, title, subtitle, id, price, href, variant }) => {
  const inner = (
    <Anchor href={href}>
      {image ? (
        <Image src={image} alt="" variant={variant} />
      ) : (
        <Box className={cn('h-14 w-14 sm:h-[72px] sm:w-[72px]', 'bg-white/50')} />
      )}
      {variant === 'product' && (
        <Content>
          <StyledSpan className="pb-2 text-base font-bold">{title}</StyledSpan>
          <StyledSpan className={cn(price ? 'pb-2' : 'pb-0')}>{subtitle}</StyledSpan>
          {price && <StyledSpan className="text-xs">{price}</StyledSpan>}
        </Content>
      )}
      {variant === 'brand' && (
        <Content>
          <StyledSpan className="py-2 text-xl">{title}</StyledSpan>
        </Content>
      )}
      {variant === 'category' && (
        <Content>
          <StyledSpan className="py-2 font-bold">{title}</StyledSpan>
        </Content>
      )}
    </Anchor>
  )

  return <a href={href}>{inner}</a>
}

export default Result
