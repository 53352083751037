import { FC } from 'react'
import { cn } from '@lib/cn'

type MenuListProps = {
  space: 'large' | 'regular' | 'small'
}

export const MenuList: FC<MenuListProps> = ({ space, children }) => {
  return (
    <ul
      data-space={space}
      className={cn(
        'm-0 p-0',
        space === 'small' && '[&>li]:mb-4',
        space === 'regular' && '[&>li]:mb-6',
        space === 'large' && '[&>li]:mb-8',
      )}
    >
      {children}
    </ul>
  )
}
