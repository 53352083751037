import { cn } from '@lib/cn'
import React, { FC, useEffect, useRef } from 'react'

type Props = {
  onRequestClose?: () => void
  variant: 'fullscreen-on-mobile' | 'default'
  menuIsActive: boolean
}

export const HeaderDropdown: FC<Props> = ({
  children,
  onRequestClose,
  variant,
  menuIsActive,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>()
  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onRequestClose()
      }
    }
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, []) // eslint-disable-line

  const handleBackdropClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (ref.current === e.target) {
      onRequestClose()
    }
  }

  return (
    <div
      ref={ref}
      className={cn(
        'fixed left-0 top-0 h-full w-full bg-[rgba(0,0,0,0.4)]',
        `z-[29]`,
        `[&[data-fullscreen-on-mobile]]:z-[99]`,
        `lg:[&[data-fullscreen-on-mobile]]:z-[29]`,
        'lg:cursor-pointer',
        '[&[data-is-visible=false]]:hidden',
      )}
      data-is-visible={menuIsActive}
      onClick={handleBackdropClick}
      data-fullscreen-on-mobile={variant === 'fullscreen-on-mobile' ? '' : undefined}
    >
      <div
        className={cn(
          'absolute top-0 z-[1] h-full max-h-full w-full cursor-default bg-pampas pt-12',
          'md:pt-[72px]',
          'lg:h-auto lg:pt-[calc(140px+24px)]',
          'xl:pt-[calc(140px+56px)]',
          'xxl:pb-[104px] xxl:pt-[calc(102px+72px)]',
        )}
        {...rest}
      >
        {children}
      </div>
    </div>
  )
}
