import LocalizedLink from '@components/Localization/LocalizedLink'
import { HideSubItemShop } from '@components/Page/Header/shop-menu/HideSubItemShop'
import { Container, Flex, Icon } from '@gassan-ui'
import { NavigationContent } from '@lib/kontent/navigation-helpers/normalize-navigation-content'
import { cn } from '@lib/cn'
import { useTranslation } from 'next-i18next'
import { FC, useState } from 'react'
import MenuButton from '../MenuButton'
import { MenuLabel } from '../MenuLabel'
import { MenuList } from '../MenuList'
import { HeaderShopMenuDiamonds } from './Diamonds'
import { HeaderShopMenuJewelry } from './Jewelry'
import { HeaderShopMenuRings } from './Rings'
import { HeaderShopMenuWatches } from './Watches'

type HeaderShopMenuProps = {
  content: NavigationContent
  isVisible: boolean
}

export const HeaderShopMenu: FC<HeaderShopMenuProps> = ({ content, isVisible }) => {
  const { t } = useTranslation(['dropdown', 'navigation'])
  const [active, setActive] = useState('watches')

  const linkButton = cn(
    'relative text-left font-heading text-shade-800 text-[24px] tracking-[0.03em] outline-0',
    'data-[variant=large]:text-[32px]',
    'data-[status-active]:after:opacity-100',
    'group',
  )

  const linkButtonSpan = cn(
    'relative',
    'group-hover:after:opacity-100',
    "after:absolute after:bottom-1 after:left-0 after:h-0.5 after:w-full after:bg-current after:opacity-0 after:transition-opacity after:duration-200 after:ease-in-out after:content-['']",
  )

  const linkButtonImage = cn('transition-all duration-300 ease-in-out', 'group-hover:translate-x-1')

  return (
    <Container
      data-is-visible={isVisible}
      className={cn(
        'grid grid-cols-[1fr_2fr] gap-x-2 pb-24 pt-10',
        'xl:grid-cols-[1fr_3fr] xl:pt-[68px]',
        !isVisible && 'hidden',
      )}
    >
      <div>
        <MenuLabel>{t('shop.categories', { ns: 'dropdown' })}</MenuLabel>
        <MenuList space="large">
          <li>
            <MenuButton
              variant="large"
              onMouseEnter={() => setActive('watches')}
              title={t('shop.watches', { ns: 'dropdown' })}
              isActive={active === 'watches'}
            >
              {t('shop.watches', { ns: 'dropdown' })}
            </MenuButton>
          </li>
          <li>
            <MenuButton
              variant="large"
              onMouseEnter={() => setActive('diamonds')}
              title={t('shop.diamonds', { ns: 'dropdown' })}
              isActive={active === 'diamonds'}
            >
              {t('shop.diamonds', { ns: 'dropdown' })}
            </MenuButton>
          </li>
          <li>
            <MenuButton
              variant="large"
              onMouseEnter={() => setActive('rings')}
              title={t('shop.weddingRings', { ns: 'dropdown' })}
              isActive={active === 'rings'}
            >
              {t('shop.weddingRings', { ns: 'dropdown' })}
            </MenuButton>
          </li>
          <li>
            <MenuButton
              variant="large"
              onMouseEnter={() => setActive('jewelry')}
              title={t('shop.jewelry', { ns: 'dropdown' })}
              isActive={active === 'jewelry'}
            >
              {t('shop.jewelry', { ns: 'dropdown' })}
            </MenuButton>
          </li>
          <li>
            <LocalizedLink href={t('accessories', { ns: 'navigation' })} passHref>
              <a
                className={linkButton}
                data-variant="large"
                title={t('shop.accessories', { ns: 'dropdown' })}
              >
                <Flex alignItems="center">
                  <span className={linkButtonSpan}>
                    {t('shop.accessories', { ns: 'dropdown' })}
                  </span>
                  <Icon icon="arrow-right" size={32} ml="8px" className={linkButtonImage} />
                </Flex>
              </a>
            </LocalizedLink>
          </li>
          <li>
            <LocalizedLink href={t('giftcards', { ns: 'navigation' })} passHref>
              <a
                className={linkButton}
                data-variant="large"
                title={t('shop.giftcard', { ns: 'dropdown' })}
              >
                <Flex alignItems="center">
                  <span className={linkButtonSpan}>{t('shop.giftcard', { ns: 'dropdown' })}</span>
                  <Icon icon="arrow-right" size={32} ml="8px" className={linkButtonImage} />
                </Flex>
              </a>
            </LocalizedLink>
          </li>
        </MenuList>
      </div>
      <div className="relative h-full w-full overflow-hidden">
        <HideSubItemShop isVisible={active === 'watches'}>
          <HeaderShopMenuWatches {...content.watches} />
        </HideSubItemShop>
        <HideSubItemShop isVisible={active === 'diamonds'}>
          <HeaderShopMenuDiamonds {...content.diamonds} />
        </HideSubItemShop>
        <HideSubItemShop isVisible={active === 'rings'}>
          <HeaderShopMenuRings {...content.weddingRings} />
        </HideSubItemShop>
        <HideSubItemShop isVisible={active === 'jewelry'}>
          <HeaderShopMenuJewelry {...content.jewelry} />
        </HideSubItemShop>
      </div>
    </Container>
  )
}
