import { ButtonHTMLAttributes, FC } from 'react'
import { cn } from '@lib/cn'

const specials = ['g', 'j', 'p', 'q', 'y', 'Q']

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: string
  variant?: 'large' | 'regular'
  isActive?: boolean
}

const MenuButton: FC<Props> = ({ children, variant = 'large', isActive, ...rest }) => {
  return (
    <button
      className={cn(
        'relative cursor-pointer text-left font-heading text-[24px] tracking-[0.03em] text-shade-800 outline-0',
        variant === 'large' && 'text-[32px]',
        'hover:after:opacity-100',
        'after:absolute after:bottom-1 after:left-0 after:z-[-2] after:h-0.5 after:w-full after:bg-current after:opacity-0 after:transition-opacity after:duration-200 after:ease-in-out after:content-[""]',
        isActive && 'after:opacity-100',
      )}
      data-status-active={isActive ? '' : undefined}
      data-variant={variant}
      dangerouslySetInnerHTML={{
        __html: children
          .split('')
          .map((letter) =>
            specials.includes(letter)
              ? `<span class="relative before:absolute before:content-[''] before:bottom-[-5px] before:w-[140%] before:h-full before:z-[-1] before:left-[-20%] before:bg-pampas">${letter}</span>`
              : letter,
          )
          .join(''),
      }}
      {...rest}
    />
  )
}
export default MenuButton
