import { HeaderImageColumn } from './image-column'
import { HeaderImageListColumn } from './image-list-column'
import { HeaderLinkColumn } from './link-column'
import { Container, Link } from '@components/gassan-ui'
import { DiamondsContent } from '@lib/kontent/navigation-helpers/get-diamonds-content'
import { cn } from '@lib/cn'
import NextLink from 'next/link'
import { FC } from 'react'

type HeaderDiamondsMenuProps = DiamondsContent & {
  isVisible: boolean
}

export const HeaderDiamondsMenu: FC<HeaderDiamondsMenuProps> = ({
  linkList,
  cutsList,
  imageLinkFirstColumn,
  imageLinkSecondColumn,
  isVisible,
}) => {
  return (
    <Container
      className={cn(
        'grid grid-cols-3 gap-x-2 pb-24 pt-10 xl:grid-cols-4 xl:pt-[68px]',
        '[&[data-is-visible=false]]:hidden',
      )}
      data-is-visible={isVisible}
    >
      <HeaderLinkColumn
        title={linkList.title}
        readMoreLink={
          linkList.viewMore && (
            <NextLink href={linkList.viewMore.url} passHref>
              <Link variant="brown">{linkList.viewMore.label}</Link>
            </NextLink>
          )
        }
        links={linkList.links}
        linkSpace="regular"
      />
      <HeaderImageListColumn title={cutsList.title} links={cutsList.links} />
      <HeaderImageColumn {...imageLinkFirstColumn} />
      <HeaderImageColumn {...imageLinkSecondColumn} showOnlyOnXlarge />
    </Container>
  )
}
