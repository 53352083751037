import LocalizedLink from '@components/Localization/LocalizedLink'
import { cn } from '@lib/cn'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { NavActiveOptions } from '../HeaderContent'

type HeaderNavigationMainNavProps = {
  onClickItem: (key: NavActiveOptions) => void
}

export const HeaderNavigationMainNav: FC<HeaderNavigationMainNavProps> = ({ onClickItem }) => {
  const { t } = useTranslation(['navigation', 'global', 'other'])

  const linkStyle = cn(
    'mr-4 inline-block border-b-0 bg-transparent text-current outline-0 duration-400 transition-all ease-in-out font-special text-[9px] uppercase tracking-[0.28em]',
    'md:text-[11px] md:mr-8',
    'focus:opacity-100',
  )

  return (
    <div
      className={cn(
        'hidden justify-around',
        'md:justify-start',
        'lg:col-span-3 lg:row-start-2 lg:flex lg:items-center lg:pt-4',
        '[&:hover>*]:opacity-50 [&>*:hover]:opacity-100',
      )}
    >
      <LocalizedLink href="/rolex" passHref>
        <a className={linkStyle}>Rolex</a>
      </LocalizedLink>
      <LocalizedLink href={t(`rcpo.index.url`, { ns: 'navigation' })}>
        <a className={linkStyle}>Rolex Certified Pre-Owned</a>
      </LocalizedLink>
      <button className={linkStyle} onClick={() => onClickItem('shop')}>
        {t(`mainNav.shop`, { ns: 'navigation' })}
      </button>
      <LocalizedLink href={t(`preOwnedWatches`, { ns: 'navigation' })}>
        <a className={linkStyle}>Pre-owned</a>
      </LocalizedLink>
      <button className={linkStyle} onClick={() => onClickItem('diamonds')}>
        {t(`mainNav.diamonds`, { ns: 'navigation' })}
      </button>
      <button className={linkStyle} onClick={() => onClickItem('house-of-gassan')}>
        {t(`mainNav.houseOfGassan`, { ns: 'navigation' })}
      </button>
      <LocalizedLink href={t(`tours`, { ns: 'navigation' })}>
        <a className={linkStyle}>Tours</a>
      </LocalizedLink>
    </div>
  )
}
