import { cn } from '../../../../lib/cn'
import { FC } from 'react'

type HideSubItemShopProps = {
  isVisible: boolean
}

export const HideSubItemShop: FC<HideSubItemShopProps> = ({ children, isVisible }) => {
  return (
    <div
      className={cn(
        'absolute left-0 top-0 h-full w-full',
        'grid grid-cols-2 gap-2',
        'xl:grid-cols-3',
        !isVisible && 'hidden',
      )}
      data-is-visible={isVisible}
    >
      {children}
    </div>
  )
}
