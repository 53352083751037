import { cn } from '@lib/cn'
import { FC } from 'react'

type PageNavigationHamburgerProps = {
  isActive: boolean
  handleClick: () => void
}

export const PageNavigationHamburger: FC<PageNavigationHamburgerProps> = ({
  isActive,
  handleClick,
}) => {
  return (
    <button
      className={cn(
        'flex h-10 w-10 flex-col items-center justify-center transition-all duration-200 ease-in-out',
      )}
      data-is-active={isActive ? '' : undefined}
      onClick={handleClick}
    >
      <span
        className={cn(
          'h-[1px] w-4 origin-top-left bg-shade-800 transition-transform duration-200 ease-in-out',
          'sm:h-0.5 sm:w-6',
          isActive ? 'translate-y-[-2px] rotate-45' : '',
        )}
      />
      <span
        className={cn(
          'mt-2 h-[1px] w-4 origin-bottom-left bg-shade-800 transition-transform duration-200 ease-in-out',
          'sm:h-0.5 sm:w-6',
          isActive ? '-rotate-45 sm:translate-y-1' : '',
        )}
      />
    </button>
  )
}
