import { cn } from '@lib/cn'
import { FC, HTMLAttributes } from 'react'

export type HeadingVariant = 'hXl' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
export type HeadingAs = 'div' | 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'

type HeadingProps = HTMLAttributes<HTMLElement> & {
  as?: HeadingAs
  variant?: HeadingVariant
}

export const defaultStylingHeading = 'font-heading tracking-[0.03em] text-shade-800'

export const variantHeadingStyles = {
  hXl: cn(
    'leading-[1.2]',
    'text-[1.75rem]',
    'md:text-[3rem]',
    'lg:tracking-[0.02em] lg:text-[3.5rem]',
    'xl:text-[4.5rem]',
  ),
  h1: cn(
    'leading-[1.3]',
    'text-[1.5rem]',
    'md:text-[2.5rem]',
    'lg:text-[2.625rem]',
    'xl:text-[3rem]',
  ),
  h2: cn(
    'leading-[1.3]',
    'text-[1.25rem]',
    'md:text-[2rem]',
    'lg:text-[2.125rem]',
    'xl:text-[2.5rem]',
  ),
  h3: cn(
    'leading-[1.4]',
    'text-[1.125rem]',
    'md:text-[1.5rem]',
    'lg:text-[1.625rem]',
    'xl:text-[2rem]',
  ),
  h4: cn(
    'leading-[1.4]',
    'text-[1rem]',
    'md:text-[1.25rem]',
    'lg:text-[1.375rem]',
    'xl:text-[1.5rem]',
  ),
  h5: cn('leading-[1.4]', 'text-[0.875rem]', 'md:text-[1.125rem]', 'lg:text-[1.25rem]'),
}

export const Heading: FC<HeadingProps> = ({
  children,
  className,
  variant = 'regular',
  as = 'p',
  ...rest
}) => {
  const Element = as
  const enableDefaultStylingMarginBottom = !className?.match(/m[bty]-/)

  return (
    <Element
      className={cn(
        defaultStylingHeading,
        enableDefaultStylingMarginBottom && 'mb-6 md:mb-8 xl:mb-10',
        variantHeadingStyles[variant],
        className,
      )}
      {...rest}
    >
      {children}
    </Element>
  )
}
