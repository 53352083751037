import { IndexItem } from '.'
import { MenuLabel } from '../MenuLabel'
import Result from './Result'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { Link } from '@gassan-ui'
import { formatPrice } from '@lib/formatPrice'
import { getKontentImage } from '@lib/kontent'
import { motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { cn } from '@lib/cn'

type Props = {
  totalProducts: number
  products: IndexItem[]
  brands: IndexItem[]
  categories: IndexItem[]
  query: string
}

const Results: FC<Props> = ({ totalProducts, products, brands, categories, query }) => {
  const { t } = useTranslation(['dropdown', 'navigation'])
  return (
    <div className={cn('flex grid-cols-[100%] flex-col flex-wrap md:grid md:grid-cols-3')}>
      {brands.length > 0 && (
        <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>
          <div className={cn('mb-4 md:mb-0')}>
            <MenuLabel>
              {brands.length}{' '}
              {t(brands.length > 1 ? 'search.brands' : 'search.brand', { ns: 'dropdown' })}{' '}
              {t('search.found', { ns: 'dropdown' })}
            </MenuLabel>
            {brands.map((item) => (
              <Result
                key={item.urlSlug}
                variant="brand"
                href={`/${item.urlSlug}`}
                title={item.title}
                price={item.price ? '1000' : formatPrice(item.price)}
                image={getKontentImage(item.image, { width: 100 })}
              />
            ))}
          </div>
        </motion.div>
      )}
      {categories.length > 0 && (
        <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>
          <div className={cn('mb-4 md:mb-0')}>
            <MenuLabel>
              {categories.length}{' '}
              {t(categories.length > 1 ? 'search.categories' : 'search.category', {
                ns: 'dropdown',
              })}{' '}
              {t('search.found', { ns: 'dropdown' })}
            </MenuLabel>
            {categories.map((item) => (
              <Result
                key={item.urlSlug}
                variant="category"
                href={`/${item.urlSlug}`}
                title={item.title}
                price={item.price ? formatPrice(item.price) : undefined}
                image={getKontentImage(item.image, { width: 100 })}
              />
            ))}
          </div>
        </motion.div>
      )}
      {totalProducts > 0 && (
        <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>
          <div className={cn('mb-4 md:mb-0')}>
            <MenuLabel>
              {totalProducts}
              {t(totalProducts > 1 ? 'search.products' : 'search.product', { ns: 'dropdown' })}{' '}
              {t('search.found', { ns: 'dropdown' })}
            </MenuLabel>
            {products.map((item, index) => {
              const href = item.brand === 'Rolex' ? `/rolex/${item.urlSlug}` : `/p/${item.urlSlug}`
              return (
                <Result
                  key={`result-${index}`}
                  variant="product"
                  id={item.objectID}
                  href={href}
                  title={item.brand}
                  subtitle={item.title}
                  price={item.price ? formatPrice(item.price) : undefined}
                  image={getKontentImage(item.image, { width: 100 })}
                />
              )
            })}
            {totalProducts > products.length && (
              <LocalizedLink
                href={{
                  pathname: t('search', { ns: 'navigation' }),
                  query: {
                    s: query,
                  },
                }}
              >
                <Link>
                  {t('search.showAllProducts', { ns: 'dropdown' }).replace(
                    '$1',
                    totalProducts.toString(),
                  )}
                </Link>
              </LocalizedLink>
            )}
          </div>
        </motion.div>
      )}
    </div>
  )
}

export default Results
