import { HeaderImageColumn } from '../../image-column'
import { HeaderLinkColumn } from '../../link-column'

import { Link } from '@components/gassan-ui'
import { WeddingRingsContent } from '@lib/kontent/navigation-helpers/get-wedding-rings-content'
import NextLink from 'next/link'
import { FC } from 'react'

type HeaderMobileNavWeddingRings = WeddingRingsContent

const WeddingRings: FC<HeaderMobileNavWeddingRings> = ({
  linkList,
  imageLinkFirstColumn,
  imageLinkSecondColumn,
}) => {
  return (
    <>
      <HeaderLinkColumn
        title={linkList.title}
        readMoreLink={
          linkList.viewMore && (
            <NextLink href={linkList.viewMore.url} passHref>
              <Link variant="blue">{linkList.viewMore.label}</Link>
            </NextLink>
          )
        }
        links={linkList.links}
        linkSpace="regular"
      />
      <div className="h-10 md:p-16" />
      <HeaderImageColumn {...imageLinkFirstColumn} />
      <div className="h-10 md:p-16" />
      <HeaderImageColumn {...imageLinkSecondColumn} />
    </>
  )
}

export default WeddingRings
