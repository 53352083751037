import { NavActiveOptions } from '../HeaderContent'
import { PageNavigationHamburger } from './Hamburger'
import { HeaderNavigationMainNav } from './MainNav'
import { HeaderNavigationSecondaryNav } from './SecondaryNav'
import Logo from '@components/Logo'
import Link from 'next/link'
import { FC } from 'react'
import { cn } from '@lib/cn'

type HeaderNavigationProps = {
  onClickItem?: (key: NavActiveOptions) => void
  active: NavActiveOptions
}

export const HeaderNavigation: FC<HeaderNavigationProps> = ({ onClickItem, active }) => {
  return (
    <nav
      className={cn(
        'relative grid items-center justify-center',
        'w-[calc(100%-16px)] max-w-[1744px] grid-cols-[auto_auto_1fr] grid-rows-[1fr] gap-1',
        'md:w-[calc(100%-48px)] md:grid-cols-[1fr_120px_1fr] md:gap-0',
        'lg:w-[calc(100%-48px)] lg:grid-cols-[1fr_1fr] lg:grid-rows-[auto_auto]',
        'xl:w-[calc(100%-88px)] xl:grid-cols-[240px_1fr_auto] xl:grid-rows-[1fr]',
        '[&[data-is-active]]:fill-shade800 [&[data-is-active]]:text-shade800',
      )}
      data-is-active={active ? '' : undefined}
    >
      <div
        className={cn(
          'col-start-2 flex items-center',
          'lg:col-start-1',
          '[&>a]:inline-block [&>a]:outline-0',
        )}
      >
        <Link href="/" passHref>
          <a>
            <Logo width="auto" height={['16px', '24px', '24px', '24px', '32px']} />
          </a>
        </Link>
      </div>

      <div className={cn('col-start-1 row-start-1 block', 'lg:hidden')}>
        <PageNavigationHamburger
          handleClick={() => onClickItem('mobile-nav')}
          isActive={!!active}
        />
      </div>

      <HeaderNavigationMainNav onClickItem={onClickItem} />

      <HeaderNavigationSecondaryNav onClickItem={onClickItem} />
    </nav>
  )
}
