import { HeaderImageColumn } from './image-column'
import { Container } from '@components/gassan-ui'
import { HouseOfGassanContent } from '@lib/kontent/navigation-helpers/get-house-of-gassan-content'
import { cn } from '@lib/cn'
import { FC } from 'react'

type HeaderHouseOfGassanMenuProps = {
  columns: HouseOfGassanContent
  isVisible: boolean
}

export const HeaderHouseOfGassanMenu: FC<HeaderHouseOfGassanMenuProps> = ({
  columns,
  isVisible,
}) => {
  return (
    <Container
      className={cn(
        'grid grid-cols-3 gap-2 pb-24 pt-10',
        'xl:grid-cols-4 xl:pt-[68px]',
        '[&[data-is-visible=false]]:hidden',
      )}
      data-is-visible={isVisible}
    >
      {columns.map((column, key) => (
        <HeaderImageColumn {...column} key={key} showOnlyOnXlarge={key === columns.length - 1} />
      ))}
    </Container>
  )
}
