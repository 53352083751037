import { MenuLabel } from '../MenuLabel'
import RecentlyViewed from './RecentlyViewed'
import Result from './Result'
import { LinkTranslated } from '@components/types'
import { getKontentImage } from '@lib/kontent'
import { motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { cn } from '@lib/cn'

type Props = {}

const NoInput: FC<Props> = () => {
  const { t } = useTranslation(['dropdown', 'dropdown-items'])
  return (
    <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>
      <div className={cn('flex flex-col flex-wrap md:grid md:grid-cols-1 lg:grid-cols-2')}>
        <div className={cn('md:order-0 order-1')}>
          <MenuLabel>{t('search.popularBrands', { ns: 'dropdown' })}</MenuLabel>
          {t<string, LinkTranslated[]>('search.popularBrands', {
            ns: 'dropdown-items',
            returnObjects: true,
          }).map((item) => (
            <Result
              key={item.urlSlug}
              variant="brand"
              href={`/${item.urlSlug}`}
              title={item.title}
              image={getKontentImage(item.image, { width: 100, height: 100 })}
            />
          ))}
        </div>
        <div className={cn('order-0 md:order-1')}>
          <RecentlyViewed />
        </div>
      </div>
    </motion.div>
  )
}

export default NoInput
