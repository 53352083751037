import { MenuLabel } from '../MenuLabel'
import Result from './Result'
import { Text } from '@components/gassan-ui/Typography/Text'
import { LinkTranslated } from '@components/types'
import { getKontentImage } from '@lib/kontent'
import { motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { cn } from '@lib/cn'

type Props = {}

const NoResults: FC<Props> = () => {
  const { t } = useTranslation(['dropdown', 'dropdown-items', 'global-items'])
  return (
    <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>
      <div>
        <Text variant="large" className={cn('mb-8 md:mb-12', 'max-w-auto md:max-w-[520px]')}>
          {t('search.notFound', { ns: 'dropdown' })}
        </Text>
        {t<string, LinkTranslated[]>('search.notFoundPages', {
          ns: 'dropdown-items',
          returnObjects: true,
        }).map((page) => (
          <Result
            key={page.urlSlug}
            title={page.title}
            image={getKontentImage(page.image, { width: 100, height: 100 })}
            href={page.urlSlug}
            variant="brand"
          ></Result>
        ))}
      </div>
      <div
        className={cn(
          'flex flex-col flex-wrap md:grid',
          'grid-cols-[100%] md:grid-cols-2',
          'gap-x-8',
          'mt-16 lg:mt-20',
        )}
      >
        <div className={cn('mb-8 md:mb-0', 'md:order-0 order-1')}>
          <MenuLabel>{t('service.customerService', { ns: 'dropdown' })}</MenuLabel>
          <Text
            as="div"
            dangerouslySetInnerHTML={{ __html: t('contactInfo', { ns: 'global-items' }) }}
          ></Text>
        </div>
        <div className={cn('mb-8 md:mb-0', 'order-2 md:order-1')}>
          <MenuLabel>{t('emailTitle', { ns: 'global-items' })}</MenuLabel>
          <Text
            as="div"
            dangerouslySetInnerHTML={{ __html: t('emailInfo', { ns: 'global-items' }) }}
          ></Text>
        </div>
      </div>
    </motion.div>
  )
}

export default NoResults
