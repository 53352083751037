import { Icon } from '@gassan-ui'
import {
  defaultStylingHeading,
  variantHeadingStyles,
} from '@components/gassan-ui/Typography/Heading'
import { cn } from '@lib/cn'
import { HTMLMotionProps, motion } from 'framer-motion'
import { FC } from 'react'

type Props = HTMLMotionProps<'div'> & {
  label: string
  isActive: boolean
  hasChildren?: boolean
  onClick: () => void
}

const Row: FC<Props> = ({ isActive, hasChildren = true, children, label, onClick, ...rest }) => {
  return (
    <motion.div
      className={cn(
        'absolute z-[1] w-full bg-pampasLight',
        'data-[status-active]:z-[2] data-[status-active]:min-h-full',
      )}
      data-status-active={isActive ? '' : undefined}
      {...rest}
    >
      <button
        className={cn(
          'relative flex w-full items-center justify-between bg-pampas outline-0',
          'relative z-[1] h-[56px] px-4',
          'md:h-[90px] md:px-8',
          'data-[status-active]:border-b data-[status-active]:border-tabaccoBrown/10',
          defaultStylingHeading,
          variantHeadingStyles['h2'],
        )}
        data-variant="h2"
        onClick={onClick}
      >
        {label}
        {hasChildren && <Icon icon={isActive ? 'chevron-up-small' : 'chevron-down-small'}></Icon>}
      </button>
      {isActive && (
        <motion.div
          className={cn('pb-24 md:pb-32')}
          initial={{ opacity: 0, display: 'none' }}
          animate={{ opacity: 1, display: 'block' }}
          transition={{ delay: 0.4, ease: 'easeOut' }}
        >
          {children}
        </motion.div>
      )}
    </motion.div>
  )
}

export default Row
