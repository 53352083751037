import { NavActiveOptions } from '../HeaderContent'
import { RolexOrTudorPlaque } from '../RolexOrTudorPlaque'
import LanguageSwitch from './LanguageSwitch'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { Icon } from '@components/gassan-ui'
import { useAuth } from '@lib/hooks/use-auth'
import { useMembershipModal } from '@lib/hooks/use-membership-modal'
import { useWishlist } from '@lib/hooks/use-wishlist'
import { useCart } from '@lib/hooks/useCart'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { cn } from '@lib/cn'

type HeaderNavigationSecondaryNavProps = {
  onClickItem: (key: NavActiveOptions) => void
  isLightText?: boolean
}

export const HeaderNavigationSecondaryNav: FC<HeaderNavigationSecondaryNavProps> = ({
  isLightText,
  onClickItem,
}) => {
  const { articleIds } = useWishlist()
  const { isLoggedIn } = useAuth()
  const { setShowMembershipModalVariant } = useMembershipModal()
  const { t } = useTranslation(['navigation', 'global'])
  const cart = useCart()
  const totalItems = cart ? cart.totalItems : 0

  return (
    <div
      className={cn(
        'group flex items-center justify-end',
        'hover:[&>[data-secondary-nav-element]]:opacity-50',
      )}
    >
      <button
        className={cn(
          'relative flex h-10 w-10 items-center justify-center',
          'duration-400 bg-transparent opacity-100 transition-all ease-in-out',
          'ml-0 sm:ml-3 xl:ml-4',
          'hover:opacity-100',
          '[&>img]:max-w-6',
        )}
        onClick={() => onClickItem('search')}
        data-secondary-nav-element
      >
        <Icon size={[32, 40]} icon="search" />
      </button>
      <LocalizedLink href={t('membership.overview.url', { ns: 'navigation' })} passHref>
        <a
          className={cn(
            'relative flex h-10 w-10 items-center justify-center',
            'duration-400 bg-transparent opacity-100 transition-all ease-in-out',
            'ml-0 sm:ml-3 xl:ml-4',
            'hover:opacity-100',
            '[&>img]:max-w-6',
            'flex lg:hidden',
          )}
          data-mobile-only
          data-secondary-nav-element
        >
          <Icon size={[32, 40]} icon="profile" />
        </a>
      </LocalizedLink>
      <LocalizedLink href={t('secondaryNav.stores.url', { ns: 'navigation' })} passHref>
        <a
          className={cn(
            'relative flex h-10 w-10 items-center justify-center',
            'duration-400 bg-transparent opacity-100 transition-all ease-in-out',
            'ml-0 sm:ml-3 xl:ml-4',
            'hover:opacity-100',
            '[&>img]:max-w-6',
            'hidden lg:flex',
          )}
          data-desktop-only
          data-secondary-nav-element
        >
          <Icon size={[32, 40]} icon="map-marker" />
        </a>
      </LocalizedLink>
      {isLoggedIn ? (
        <LocalizedLink href={t('membership.wishlist.url', { ns: 'navigation' })} passHref>
          <a
            className={cn(
              'relative flex h-10 w-10 items-center justify-center',
              'duration-400 bg-transparent opacity-100 transition-all ease-in-out',
              'ml-0 sm:ml-3 xl:ml-4',
              'hover:opacity-100',
              '[&>img]:max-w-6',
              'hidden lg:flex',
            )}
            data-desktop-only
            data-secondary-nav-element
          >
            <Icon size={[32, 40]} icon="heart" />
            {articleIds && articleIds.length > 0 && (
              <span
                className={cn(
                  'absolute flex items-center justify-center',
                  'font-specials bg-bigStoneLight text-white',
                  'right-1.5 top-1.5 h-3.5 w-3.5 rounded-full p-0 text-[9px]',
                  'sm:rounded-4 sm:right-1 sm:top-[5px] sm:h-4 sm:w-4 sm:text-[11px]',
                  'data-[is-two-digits]:right-0.5 data-[is-two-digits]:w-auto data-[is-two-digits]:px-1',
                  'sm:data-[is-two-digits]:right-[-1px]',
                  'data-[is-light-text]:bg-pampas data-[is-light-text]:text-bigStone',
                )}
                data-is-light-text={isLightText ? '' : undefined}
                data-is-two-digits={articleIds.length > 9 ? '' : undefined}
              >
                {articleIds.length}
              </span>
            )}
          </a>
        </LocalizedLink>
      ) : (
        <button
          className={cn(
            'relative flex h-10 w-10 items-center justify-center',
            'duration-400 bg-transparent opacity-100 transition-all ease-in-out',
            'ml-0 sm:ml-3 xl:ml-4',
            'hover:opacity-100',
            '[&>img]:max-w-6',
            'hidden lg:flex',
          )}
          data-desktop-only
          data-secondary-nav-element
          onClick={() => setShowMembershipModalVariant('wishlist')}
        >
          <Icon size={[32, 40]} icon="heart" />
        </button>
      )}
      <LocalizedLink href={t('secondaryNav.cart.url', { ns: 'navigation' })} passHref>
        <a
          className={cn(
            'relative flex h-10 w-10 items-center justify-center',
            'duration-400 bg-transparent opacity-100 transition-all ease-in-out',
            'ml-0 sm:ml-3 xl:ml-4',
            'hover:opacity-100',
            '[&>img]:max-w-6',
          )}
          data-secondary-nav-element
        >
          <Icon size={[32, 40]} icon="shopping-bag" />
          {totalItems > 0 && (
            <span
              className={cn(
                'absolute flex items-center justify-center',
                'font-specials bg-bigStoneLight text-white',
                'right-1.5 top-1.5 h-3.5 w-3.5 rounded-full p-0 text-[9px]',
                'sm:rounded-4 sm:right-1 sm:top-[5px] sm:h-4 sm:w-4 sm:text-[11px]',
                'data-[is-two-digits]:right-0.5 data-[is-two-digits]:w-auto data-[is-two-digits]:px-1',
                'sm:data-[is-two-digits]:right-[-1px]',
                'data-[is-light-text]:bg-pampas data-[is-light-text]:text-bigStone',
              )}
              data-is-light-text={isLightText ? '' : undefined}
              data-is-two-digits={articleIds.length > 9 ? '' : undefined}
              data-test-id="cart-count-indicator"
            >
              {totalItems}
            </span>
          )}
        </a>
      </LocalizedLink>
      <LanguageSwitch />
      <div className="ml-4 hidden lg:block">
        <RolexOrTudorPlaque />
      </div>
    </div>
  )
}
