import { HeaderContent } from './HeaderContent'
import { useHeader } from './utils/useHeader'
import { NavigationContent } from '@lib/kontent/navigation-helpers/normalize-navigation-content'
import { cn } from '@lib/cn'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { useInView } from 'react-intersection-observer'

const navigationContentEN = require('../../../__generated__/navigation-en.json')
const navigationContentNL = require('../../../__generated__/navigation-nl.json')
const navigationContentDE = require('../../../__generated__/navigation-de.json')

export type Variant = 'absolute' | 'fixed'
export type Visibility = 'hidden' | 'visible' | null
export type Style = 'dark' | 'light'

type Props = {
  initialVariant?: Variant
  canHeaderShow?: boolean
  headerStyle?: Style
}

const localizedContent = {
  en: navigationContentEN as NavigationContent,
  nl: navigationContentNL as NavigationContent,
  de: navigationContentDE as NavigationContent,
}

const Header: FC<Props> = ({
  initialVariant = 'absolute',
  canHeaderShow,
  headerStyle = 'dark',
}) => {
  const {
    i18n: { language },
  } = useTranslation()
  const content: NavigationContent =
    localizedContent[language as 'en' | 'nl' | 'de'] ?? localizedContent.en

  const [topRef, topInView] = useInView()
  const [headerRef, headerInView] = useInView()
  const { variant, visibility } = useHeader({
    topInView,
    headerInView,
    canHeaderShow,
    initialVariant,
  })

  return (
    <>
      <HeaderContent
        variant={variant}
        visibility={visibility}
        headerStyle={headerStyle}
        content={content}
      />
      {initialVariant === 'absolute' && (
        <>
          <div
            className={cn(
              'pointer-events-none invisible absolute left-0 top-0 h-[1px] w-full opacity-0',
              'lg:h-[calc(40px+1px)]',
            )}
            ref={topRef}
          />
          <div
            className={cn(
              'pointer-events-none invisible relative h-[48px] w-full opacity-0',
              'md:h-[72px] lg:h-[140px]',
            )}
            ref={headerRef}
          />
        </>
      )}
    </>
  )
}
export default Header
