import { ListLink } from '../utils/types'
import { Text } from '@components/gassan-ui/Typography/Text'
import LocalizedLink from '@components/Localization/LocalizedLink'
import React, { FC } from 'react'
import { cn } from '@lib/cn'

type HeaderLinkColumnProps = {
  title: string
  readMoreLink?: React.ReactNode
  links: ListLink[]
  linkSpace: 'large' | 'regular'
}

export const HeaderLinkColumn: FC<HeaderLinkColumnProps> = ({
  title,
  readMoreLink,
  links,
  linkSpace,
}) => {
  return (
    <div>
      <Text variant="small" className="mb-4 text-shade-800">
        {title}
      </Text>
      <ul>
        {links.map((item, key) => {
          const itemUrl = item.url.startsWith('/') ? item.url : `/${item.url}`
          return (
            <li key={key}>
              <LocalizedLink href={itemUrl} passHref>
                <a
                  className={cn(
                    'relative mb-6 inline-block bg-[length:100%_1px] bg-[position:0_100%] bg-no-repeat text-[14px] text-shade-800',
                    'sm:text-[20px]',
                    'xl:text-[24px]',
                    'hover:bg-[linear-gradient(to_bottom,black_0%,black_100%)]',
                    'focus:bg-[linear-gradient(to_bottom,black_0%,black_100%)]',
                    linkSpace === 'large' && 'mb-8',
                  )}
                  data-space={linkSpace}
                >
                  {item.label}
                </a>
              </LocalizedLink>
            </li>
          )
        })}
      </ul>
      {readMoreLink && readMoreLink}
    </div>
  )
}
