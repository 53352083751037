import { Heading } from '@components/gassan-ui/Typography/Heading'
import { Icon } from '@gassan-ui'
import { cn } from '@lib/cn'
import { HTMLMotionProps, motion } from 'framer-motion'
import { FC } from 'react'

const motionPropsParent = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { ease: 'easeOut', duration: 0.2, delay: 0.2 },
}

const motionPropsContent = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { ease: 'easeOut', duration: 0.2, delay: 0.2 },
}

type Props = HTMLMotionProps<'div'> & {
  label: string
  onClick: () => void
  isActive: boolean
}

const Subrow: FC<Props> = ({ children, label, onClick, isActive, ...rest }) => {
  return (
    <motion.div {...motionPropsParent} {...rest}>
      <motion.button
        className={cn(
          'group relative m-0 flex h-[56px] w-full items-center justify-between border-0 border-transparent p-4 text-left outline-0 transition-all duration-200 ease-out',
          'md:px-8',
          isActive
            ? ['bg-pampas', 'md:h-[90px]']
            : ['border-b border-tabaccoBrown/10', 'md:h-[70px]'],
        )}
        data-status-active={isActive ? '' : undefined}
        onClick={onClick}
      >
        <Heading variant="h2" as="h2" className="mb-0">
          {label}
        </Heading>
        <Icon icon={isActive ? 'chevron-up-small' : 'chevron-down-small'}></Icon>
      </motion.button>
      {isActive && (
        <motion.div
          {...motionPropsContent}
          className={cn('h-full bg-pampasLight px-4 py-6', 'md:p-8')}
        >
          {children}
        </motion.div>
      )}
    </motion.div>
  )
}

export default Subrow
