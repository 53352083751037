export function AccountServiceLarge() {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.1669 19.6666L18.46 18.3735C18.6501 18.1834 18.9346 18.1317 19.194 18.2029C19.3953 18.2581 19.6035 18.2967 19.817 18.3169M22.0062 11.494L20.2544 13.2458C19.9904 13.5098 19.8584 13.6418 19.809 13.794C19.7654 13.9279 19.7654 14.0721 19.809 14.206C19.8584 14.3582 19.9904 14.4902 20.2544 14.7542L20.4126 14.9124C20.6766 15.1764 20.8086 15.3084 20.9608 15.3579C21.0947 15.4014 21.239 15.4014 21.3728 15.3579C21.525 15.3084 21.6571 15.1764 21.9211 14.9124L23.5597 13.2738C23.7362 13.7033 23.8335 14.1736 23.8335 14.6667C23.8335 16.6917 22.1919 18.3333 20.1668 18.3333C19.9227 18.3333 19.6841 18.3095 19.4534 18.2639C19.1292 18.2001 18.9672 18.1681 18.869 18.1779C18.7645 18.1883 18.713 18.2039 18.6205 18.2535C18.5334 18.3001 18.4462 18.3873 18.2715 18.562L13.5002 23.3333C12.9479 23.8856 12.0524 23.8856 11.5002 23.3333C10.9479 22.7811 10.9479 21.8856 11.5002 21.3333L16.2715 16.562C16.4462 16.3873 16.5334 16.3 16.58 16.213C16.6296 16.1205 16.6452 16.069 16.6556 15.9645C16.6654 15.8663 16.6334 15.7043 16.5696 15.3802C16.524 15.1494 16.5002 14.9108 16.5002 14.6667C16.5002 12.6416 18.1418 11 20.1668 11C20.8372 11 21.4655 11.1799 22.0062 11.494Z"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.166 8.6665L12.689 9.71244C12.866 10.0664 12.9545 10.2434 13.0727 10.3968C13.1776 10.5329 13.2996 10.6549 13.4357 10.7598C13.5891 10.878 13.7661 10.9666 14.1201 11.1435L15.166 11.6665L14.1201 12.1895C13.7661 12.3664 13.5891 12.455 13.4357 12.5732C13.2996 12.6781 13.1776 12.8001 13.0727 12.9362C12.9545 13.0896 12.866 13.2666 12.689 13.6206L12.166 14.6665L11.643 13.6206C11.466 13.2666 11.3776 13.0896 11.2593 12.9362C11.1544 12.8001 11.0324 12.6781 10.8963 12.5732C10.7429 12.455 10.5659 12.3664 10.212 12.1895L9.16602 11.6665L10.212 11.1435C10.5659 10.9666 10.7429 10.878 10.8963 10.7598C11.0324 10.6549 11.1544 10.5329 11.2593 10.3968C11.3776 10.2434 11.466 10.0664 11.643 9.71244L12.166 8.6665Z"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
