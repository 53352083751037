import { Text, TextAs, TextVariant } from '@components/gassan-ui/Typography/Text'
import { FC } from 'react'

type MenuLabelProps = {
  variant?: TextVariant
  as?: TextAs
}

export const MenuLabel: FC<MenuLabelProps> = ({ variant = 'small', as = 'div', children }) => {
  return (
    <Text className="flex items-center text-shade-800/80" variant={variant} as={as}>
      {children}
    </Text>
  )
}
