import { ImageLinkColumn } from '../utils/types'
import { Image } from '@components/gassan-ui'
import { getKontentImage } from '@lib/kontent'
import Link from 'next/link'
import { FC } from 'react'
import { cn } from '@lib/cn'

type HeaderImageColumnProps = ImageLinkColumn & {
  showOnlyOnXlarge?: boolean
}

export const HeaderImageColumn: FC<HeaderImageColumnProps> = ({
  mobileSrc,
  desktopSrc,
  alt,
  url,
  label,
  showOnlyOnXlarge = false,
}) => {
  return (
    <div
      className={cn(
        'relative z-[1] flex w-full overflow-hidden',
        'before:block before:w-full before:pt-[66%] before:transition-all before:duration-300 before:ease-in-out before:content-[""]',
        'lg:before:pt-[100%]',
        'group hover:before:bg-black/50 hover:before:transition-all hover:before:delay-100 hover:before:duration-300',
        showOnlyOnXlarge ? 'hidden xl:flex' : '',
      )}
    >
      <Link href={url}>
        <a className="absolute inset-0 h-full w-full">
          <Image
            placeholderSrc={getKontentImage(desktopSrc, {
              width: 40,
            })}
            sources={[
              getKontentImage(mobileSrc, { width: 768 }),
              getKontentImage(mobileSrc, { width: 1024 }),
              getKontentImage(desktopSrc, { width: 600 }),
            ]}
            className="absolute inset-0 z-[-1]"
            alt={alt || ''}
          />
          <div
            data-content
            className={cn(
              'absolute bottom-0 left-0 z-[1] flex w-full items-center',
              'py-2 md:py-4 lg:py-6',
              'before:absolute before:inset-0 before:-z-[1] before:block before:bg-pampasLight before:transition-all before:duration-300',
              'md:before:bg-pampas',
              'group-hover:before:translate-y-full group-hover:before:transition-all group-hover:before:duration-300',
            )}
          >
            <div
              data-title
              className={cn(
                'mb-0 border-b border-current pb-1 font-special text-[9px] uppercase leading-[1.45] tracking-[0.28em]',
                'transition-all delay-100 duration-100',
                'md:text-[11px]',
                'group-hover:opacity-0 group-hover:transition-all group-hover:duration-100',
              )}
            >
              {label}
            </div>
          </div>
          <div
            data-hover-content
            className={cn(
              'absolute bottom-0 left-0 flex w-full items-start text-left text-white',
              'translate-y-2 opacity-0 transition-all duration-300',
              'flex-col px-4 py-6',
              'group-hover:translate-y-0 group-hover:opacity-100 group-hover:delay-150',
            )}
          >
            <div
              className={cn(
                'mb-0 border-b border-current pb-1 font-special text-[9px] uppercase leading-[1.45] tracking-[0.28em]',
                'translate-y-2 opacity-0 transition-all duration-150',
                'md:text-[11px]',
                'group-hover:translate-y-0 group-hover:opacity-100',
              )}
            >
              {label}
            </div>
          </div>
        </a>
      </Link>
    </div>
  )
}
