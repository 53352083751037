import { HeaderImageColumn } from '../../image-column'
import { HeaderImageListColumn } from '../../image-list-column'
import { HeaderLinkColumn } from '../../link-column'

import { Link } from '@components/gassan-ui'
import { DiamondsContent } from '@lib/kontent/navigation-helpers/get-diamonds-content'
import NextLink from 'next/link'
import { FC } from 'react'

type HeaderMobileNavDiamonds = DiamondsContent & {
  variant?: 'extended' | 'minimal'
}

const Diamonds: FC<HeaderMobileNavDiamonds> = ({
  linkList,
  cutsList,
  imageLinkFirstColumn,
  imageLinkSecondColumn,
  variant = 'minimal',
}) => {
  return (
    <>
      <HeaderLinkColumn
        title={linkList.title}
        readMoreLink={
          linkList.viewMore && (
            <NextLink href={linkList.viewMore.url} passHref>
              <Link variant="blue">{linkList.viewMore.label}</Link>
            </NextLink>
          )
        }
        links={linkList.links}
        linkSpace="regular"
      />
      {variant === 'extended' && (
        <>
          <div className="h-10 md:p-16" />
          <HeaderImageListColumn
            title={cutsList.title}
            readMoreLink={
              cutsList.viewMore && (
                <NextLink href={cutsList.viewMore.url} passHref>
                  <Link variant="blue">{cutsList.viewMore.label}</Link>
                </NextLink>
              )
            }
            links={cutsList.links}
          />
        </>
      )}
      <div className="h-10 md:p-16" />
      <HeaderImageColumn {...imageLinkFirstColumn} />
      <div className="h-10 md:p-16" />
      <HeaderImageColumn {...imageLinkSecondColumn} />
    </>
  )
}

export default Diamonds
